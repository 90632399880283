<template>
    <div id="__layout__content" class="flex flex-col min-h-screen relative z-0">
        <Header class="z-10" />
        <div class="relative flex-grow pt-16 xl:pt-32 3xl:pt-40 z-2">
            <Nuxt />
        </div>
        <div class="container flex justify-start">
            <CookieConsent v-if="!$cookies.get('cookie_is_active')" is-compact />
        </div>
        <Footer class="relative mt-24 lg:mt-36 z-[-1]" />
    </div>
</template>
<script>
import Footer from '~/components/Layout/Footer.vue';
import Header from '~/components/Layout/Header.vue';
import meta from '@/mixins/meta';
import CookieConsent from '@/components/Layout/CookieConsent.vue';
export default {
    name: 'Default',
    components: {
        Header,
        Footer,
        CookieConsent,
    },
    mixins: [meta],
    data() {
        return {
            originalTitle: '',
            missYouText: 'Máris hiányzol... 😕',
        };
    },
    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.visibilityChange);
        window.removeEventListener('blur', this.setOffline);
        window.removeEventListener('focus', this.setOnline);
    },
    mounted() {
        this.originalTitle = document.title;
        this.isWhispersOfWood = this.$route.path === '/whispers-of-wood';

        document.addEventListener('visibilitychange', this.visibilityChange);
        window.addEventListener('blur', this.setOffline);
        window.addEventListener('focus', this.setOnline);
    },
    methods: {
        setOnline() {
            if (this.originalTitle) {
                document.title = this.originalTitle;
            }
        },
        setOffline() {
            if (document.title !== this.missYouText) {
                this.originalTitle = document.title;
                document.title = this.missYouText;
            }
        },
        visibilityChange() {
            const visibilityState = document.visibilityState || 'unsupported';

            if (visibilityState === 'hidden') {
                this.setOffline();
            }
            if (visibilityState === 'visible') {
                this.setOnline();
            }
        },
    },
};
</script>
